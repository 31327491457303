<template>
  <validation-observer ref="form">
    <form @submit="submit">
      <!-- total points on the quiz -->
      <div v-if="is_submitted"
           class="alert alert-success p-2"
           role="alert">
        Twój wynik to: {{ totalPoints }}/{{ maxPoints }} punkty
      </div>
        <div
            class="questions mb-1 border-bottom"
            v-for="(question, idx) in quiz.questions"
            :key="idx"
        >
          <validation-provider
              #default="{ errors }"
              name="Pytanie"
              rules="required"
          >
            <RadiogroupPublic
                :question="question"
                v-model="answers[idx]"
                :feedback="feedback[question.id]"
                :is_submitted="is_submitted"
                :state="errors.length > 0 ? false:null"
            ></RadiogroupPublic>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <div class="mb-3">
          <h3 class="mb-2 mt-2">Twoje dane</h3>
          <div class="mb-2">
            <validation-provider
                #default="{ errors }"
                name="Imię i nazwisko"
                rules="required"
                class="mb-2"
            >
              <b-form-input
                  id="name"
                  v-model="name"
                  placeholder="Imię i nazwisko"
                  :state="errors.length > 0 ? false:null"
                  trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
          <div class="mb-2">
            <validation-provider
                #default="{ errors }"
                name="Twój email"
                rules="required|email"
            >
              <b-form-input
                  id="email"
                  v-model="email"
                  :state="errors.length > 0 ? false:null"
                  name="login-email"
                  placeholder="john@example.com"

              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>

          <validation-provider
              #default="validationContext"
              name="Regulamin"
              rules="required"
          >
            <b-form-checkbox
                id="terms"
                v-model="terms"
                class="mb-2"
                :state="getValidationState(validationContext)"
                required
            >
              <small class="font-weight-bolder">Wyrażam zgodę na przetwarzanie danych osobowych oraz
                otrzymywanie newslettera i informacji
                marketingowych drogą e-mailową od Administratora danych: Sonia Radosz, prowadzącą działalność
                gospodarczą pod firmą „ChemMaster Sonia Radosz” pod adresem: ul. Wodzisławska 4,
                44-240 Żory, NIP: 6381739715. Szczegóły dotyczące przetwarzania danych znajdziesz w
                <router-link to="/polityka-prywatnosci">Polityce Prywatności</router-link>
                .</small>
            </b-form-checkbox>
          </validation-provider>
        </div>
        <b-card-text
            v-if="errors.length"
            class="mb-2 bg-danger bg-lighten-2 p-1"
            style="color:white"
        >
          <p
              v-for="error in errors"
              class="mb-0"
          >
            {{ error }}
          </p>
        </b-card-text>
        <button v-if="!is_submitted" class="btn btn-success">Prześlij odpowiedzi</button>

      <div v-if="is_submitted"
           class="alert alert-success p-2"
           role="alert">
        Twój wynik to: {{ totalPoints }}/{{ maxPoints }} punkty
      </div>
    </form>
  </validation-observer>
</template>
<script>
import {BCardText, BFormCheckbox, BFormInput, BListGroup, BListGroupItem} from 'bootstrap-vue'
import Radiogroup from './Radiogroup.vue'
import QuizAnswer from './QuizAnswer.vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Polish from 'vee-validate/dist/locale/pl'
import RadiogroupPublic from "@/views/quiz/RadiogroupPublic.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from '@validations'

export default {
  props: ['quiz', 'refillable'],
  components: {
    RadiogroupPublic,
    BCardText,
    BFormCheckbox,
    BFormInput,
    Radiogroup,
    QuizAnswer,
    BListGroup,
    BListGroupItem,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      questions: this.quiz.questions,
      is_submitted: false,
      answers: [],
      errors: [],
      feedback: {},
      all_answers: {},
      email: '',
      name: '',
      terms: '',
    }
  },
  async mounted() {
   // await this.loadAnswers()
  },
  created() {
    localize(this.locale, {
      messages: Polish.messages,
      attributes: {
        name: 'Imię i nazwisko',
        email: 'E-mail',
        terms: 'Regulamin',
      },
    })
  },
  computed: {
    maxPoints() {
      return this.quiz.questions.length
    },
    totalPoints() {
      let total = 0

      // eslint-disable-next-line no-restricted-syntax
      for (const question of this.quiz.questions) {
        total += this.all_answers[question.id].points
      }

      return total.toFixed(0)
    },
  },
  watch: {},
  setup(props, context) {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
      window.scrollTo(0, 0)
    })

    return {

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      // Form Validators
      required,
    }
  },
  methods: {
    calculatePercentage(answer) {
      return Math.round(((answer.total_points / this.maxPoints) * 100))
    },

    async loadAnswers() {
      this.$http.get(`${this.$store.state.apiDomain}/api/quizanswer/show/${this.quiz.id}`).then(response => {
        this.all_answers = response.data;
      })
    },
    async submit(e) {
      // do not refresh on submit
      e.preventDefault()

      this.$refs.form.validate().then(success => {
        this.errors = []

        if (success) {
          // check that user completed the quiz

          // make a data object
          const data = {
            quiz_id: this.quiz.id,
            answers: this.answers,
            email: this.email,
            name: this.name,
          }

          this.$http.post(`${this.$store.state.apiDomain}/api/quiz/${this.quiz.id}/saveAnswers`, data).then(response => {
            this.is_submitted = true
            this.all_answers = response.data
          })
        }
      });
    },
  },
};
</script>
<style>
form img{
  max-width: 100%;
}
</style>
