<template>
  <div class="container">
    <BRow>
      <BCol class="text-center pt-3 pb-3">
        <img :src="logo" alt="Logo" style="max-width: 100%; width: 350px;"/>
      </BCol>
    </BRow>
    <BRow>
      <BCol sm="3" col="12" class="d-none d-sm-block">
        <img :src="sonia" alt="Sonia" style="width: 500px;"/>
      </BCol>
      <BCol sm="6" col="12">
        <b-card>
          <b-card-text>
            <h3 class="mb-5">Plik pdf z odpowiedziami i komentarzami do zadań zostanie do Ciebie wysłany już jutro 😉</h3>
            <QuizPublic
                :quiz="quiz"
                :refillable="false"
                key="alochemora"
            />
          </b-card-text>
        </b-card>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { $themeConfig } from '@themeConfig'
import QuizPublic from "@/views/quiz/QuizPublic.vue";

const { appLogoImage } = $themeConfig.app

export default {
  components: {
    QuizPublic,
    BCard,
    BRow,
    BCol,
    BCardText,
    // VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      logo: require('@/assets/images/alochemora/alochemora-zlota.png'),
      sonia: require('@/assets/images/alochemora/sonia-na-miotle.png'),
      background: require('@/assets/images/alochemora/HP.png'),
      // validation rulesimport store from '@/store/index'
      id: 200,
      quiz: {},
    }
  },
  computed: {
  },
  created() {
    this.getQuiz()
  },
  methods: {
    getQuiz() {
      this.$http.get(`${this.$store.state.apiDomain}/api/quiz/${this.id}/getQuiz`).then(response => {
        if (response.data.status) {
          this.quiz = response.data.quiz
        }
      })
    },
  },
}
</script>

<style lang="scss">

body{
  background-image: url('~@/assets/images/alochemora/HP.png');
  font-size: 16px;
}
.quiz-black,
.quiz-black a,
.quiz-black p,
.quiz-black span,
.quiz-black sup,
.quiz-black sub{
  font-size: 16px;
}
.quiz-black p{
  margin-bottom: 7px!important;
  text-align: left !important;
  text-indent: 15px;
}
.quiz-black p:first-child{
  text-indent: 0;
}
</style>
